import { CPHelpSection } from "../Views/Homepage/types"


export const helpCategoryURL = (section: CPHelpSection | undefined) => {
  const params = [
    'ayuda',
    section?.id,
    encodeURIComponent(
      section !== undefined  && (
        section?.title.replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      )
    )
  ]
  return `/${params.join('/').toLowerCase()}`
}