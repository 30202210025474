import { Link } from 'react-router-dom'
import { calculateURL } from '../../Utils'
import { ArticleHightlightProps } from './types'

export default function ArticleHightlight({ article }: ArticleHightlightProps) {
  return (
    <div id='highlight_textContent_new'>
      <h5>Destacado</h5>
      <div id='highlight_image' style={{
        backgroundImage: article.image ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")` : undefined
      }}>
        {(article.category.id === "1") 
          ? <Link to='/noticias' id='blog_articles'>
              {article.category.title}
            </Link>
          : <Link to='/ayuda' id='blog_articles'>
              {article.category.title}
            </Link>
        }
      </div>
      <h3 id='highlight_textTitle'>
        <Link to={calculateURL(article)}>
            {article?.title}
        </Link>
      </h3>
      <p id='highlight_textPreview' dangerouslySetInnerHTML={{
        __html: article?.resume
      }} />
    </div>
  )
}
