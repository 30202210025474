import { Link } from 'react-router-dom'
import { calculateURL, humanDate } from '../../Utils'
import './index.scss'
import { LowerArticlesType } from './types'

export default function LowerArticles({ articles }: LowerArticlesType.Props) {
  return (
    <div className='LowerArticles'>
      {articles.map((article, index) => (
        <div className='LowerArticles_Unit' key={`LowerArticle${index}`}>
          <div className='LowerArticles_Content'>
            <div className='LowerArticles_Content_In'>
              <div className='LowerArticles_Image' style={{
                  backgroundImage: article.image ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")` : undefined
                }}></div>
              <div className='LowerArticles_Text'>
                <div className='LowerArticles_Category'>
                  {article?.category && <Link to='/' id='link_news'>
                    {article.category.title}
                  </Link>}
                </div>
                <h4>
                  <Link to={calculateURL(article)}>
                    {article.title}
                  </Link>
                </h4>
                <div className='LowerArticles_Category_Small' dangerouslySetInnerHTML={{
                  __html: humanDate(article)
                }} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
