import { gql } from '@apollo/client'

export const GET_HELP_SECTION = gql`
  query getArticlesFromHome {
    CP_Help_Sections {
      id
      icon
      title
    }
    CP_Article {
      id
      title
      image {
        id
      }
      resume
      sort
      status
      content
      date_created
      date_updated
      category {
        id
        icon
        title
      }
      help_section {
        id
        icon
        title
      }
      comments {
        id
        title
        comment
      }
    }
  }
`
