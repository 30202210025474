import useMessage from "@rottitime/react-hook-message-event";

import "./index.scss";
// import useMobile from '../../Hooks/useMobile'

import { useDispatch } from "react-redux";
import { externalLogin } from "../../Store/Actions";
import { ContactUs } from "../../Components";
import {
  MdAssignment,
  MdGroupWork,
  MdKeyboardBackspace,
  MdMonetizationOn,
  MdSchool,
  MdWork,
} from "react-icons/md";
import { HelpByCategoryType } from "./types";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CPArticle, CPHelpSection } from "../Homepage/types";
import { useQuery } from "@apollo/client";
import { GET_HELP_SECTION } from "./connection";
import { calculateURL } from "../../Utils";

const parseParams = (params: HelpByCategoryType.RouteParams) => {
  return {
    sectionId: params.sectionId,
    slug: params.slug,
  };
};

export default function HelpByCategory() {
  const { sectionId } = parseParams(
    useParams<HelpByCategoryType.RouteParams>()
  );

  const [articles, setArticles] = useState<CPArticle[]>([]);
  const [sections, setSections] = useState<CPHelpSection[]>([]);

  const { data } = useQuery<HelpByCategoryType.Response>(GET_HELP_SECTION, {
    variables: {
      ID: sectionId,
    },
  });

  useEffect(() => {
    if (data?.CP_Article) {
      setArticles(data.CP_Article);
    }

    if (data?.CP_Help_Sections) {
      setSections(data.CP_Help_Sections);
    }
  }, [data]);

  let currentSection = "";
  sections.forEach((section, index) =>
    section.id === sectionId ? (currentSection = section.title) : ""
  );

  const currentArticles: CPArticle[] = articles
    .filter((article) => article.help_section?.id === sectionId)
    .sort((a, b) => Number(a.id) - Number(b.id));
  const [firstArticle, secondArticle, ...otherArticles] = currentArticles;
  const lastArticle = otherArticles[otherArticles.length - 1];

  const dispatch = useDispatch();

  useMessage("external-login", (send: any, payload: any) => {
    dispatch(externalLogin(payload));
  });

  return (
    <div className="HelpByCategory animate__animated animate__fadeIn animate__faster">
      <div className="HelpByCategory__Category">
        <div className="HelpByCategory__Category_Backspace">
          <Link to="/ayuda">
            <MdKeyboardBackspace />
            <h3>Ayuda&nbsp;</h3>
          </Link>
          <h3>/ {currentSection} </h3>
        </div>
        <div className="HelpByCategory__Category_Name">
          {currentSection === "Primeros pasos" ? (
            <MdSchool />
          ) : currentSection === "Espacios" ? (
            <MdGroupWork />
          ) : currentSection === "Tareas" ? (
            <MdAssignment />
          ) : currentSection === "Clientes" ? (
            <MdWork />
          ) : currentSection === "Ventas" ? (
            <MdMonetizationOn />
          ) : (
            ""
          )}
          <h1>{currentSection}</h1>
        </div>
        <div className="HelpByCategory__Category_Frame">
          <div className="HelpByCategory__Category_Frame_Description">
            <h4 dangerouslySetInnerHTML={{ __html: firstArticle?.resume }}></h4>
            <p dangerouslySetInnerHTML={{ __html: firstArticle?.content }}></p>
          </div>

          {/* Pancho dejo esta caja pendiente por contenido
            <div className='HelpByCategory__Category_Frame_Icon'>
            <MdOutlineSchool/>
            <h3>Quick Start</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam illo voluptatibus, dolor</p>
          </div> */}
        </div>
      </div>
      {/* <div className='HelpByCategory__Highlight'>
        <h5>Destacados</h5>
        <LowerArticles articles={[]} />
      </div> */}
      <div className="HelpByCategory__Articles">
        <h5>Artículos</h5>
        <div className="HelpByCategory__Articles_Frame" id="first">
          {secondArticle !== undefined && (
            <Link to={calculateURL(secondArticle)}>
              <h4>{secondArticle.title}</h4>
              <p>&nbsp;-&nbsp;</p>
              <p dangerouslySetInnerHTML={{ __html: secondArticle.resume }}></p>
            </Link>
          )}
        </div>
        {otherArticles.slice(0, -1).map((article, index) => (
          <div className="HelpByCategory__Articles_Frame">
            <Link to={calculateURL(article)}>
              <h4>{article.title}</h4>
              <p>&nbsp;-&nbsp;</p>
              <p dangerouslySetInnerHTML={{ __html: article.content }}></p>
            </Link>
          </div>
        ))}
        <div className="HelpByCategory__Articles_Frame" id="last">
          {lastArticle !== undefined && (
            <Link to={calculateURL(lastArticle)}>
              <h4>{lastArticle.title}</h4>
              <p>&nbsp;-&nbsp;</p>
              <p dangerouslySetInnerHTML={{ __html: lastArticle.content }}></p>
            </Link>
          )}
        </div>
      </div>

      <div className="HelpByCategory__Contact">
        <ContactUs />
      </div>
    </div>
  );
}
