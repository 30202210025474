import { Link } from 'react-router-dom'
import { MdSearch } from "react-icons/md";

import './index.scss'

export default function SearchSection() {

  return (
    <section className='Search__Blog'>
        <div className='Search__Blog_Wrapper'>
          <div className='Search__Blog_Filters'>
            <div className='Search__Blog_Category'>
              <Link to='/' id='blog_articles'>
                Lorem
              </Link>
              <Link to='/' id='blog_articles'>
                Ipsum
              </Link>
              <Link to='/' id='blog_articles'>
                dolor
              </Link>
              <Link to='/' id='blog_articles'>
                consectetu
              </Link>
              <Link to='/' id='blog_articles'>
                Lorem
              </Link>
              <Link to='/' id='blog_articles'>
                Ipsum
              </Link>
              <Link to='/' id='blog_articles'>
                dolor
              </Link>
            </div>
            <div id='blog_search_'>
              <input
                value=""
                placeholder="Busca lo que necesites :)"
              />
              <button id='blog_search_button'>
                < MdSearch />
              </button>
            </div>
          </div>
        </div>
    </section>
  )
}