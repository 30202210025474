import { gql } from '@apollo/client'

export const GET_NEWS = gql`
  query getArticlesFromHome {
    CP_Article (
      sort: ["sort", "-date_created"]
      limit: 7
    ) {
      id
      title
      image {
        id
      }
      resume
      sort
      status
      content
      date_created
      date_updated
      category {
        id
        icon
        title
      }
      comments {
        id
        title
        comment
      }
    }
  }
`
