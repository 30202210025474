import { Link } from 'react-router-dom'

import './index.scss'

export default function ContactUs() {

  return (
    <div className='Contact'>
      <div className="Contact_Content">
          <div className='Contact_Content_Image'></div>
      </div>
      <div className="Contact_Content">
          <div className='Contact_Content_Attendance'>
            <h3 className="headline">¿Requieres asistencia?</h3>
            <Link to='/' id='contact_button'>
              Contáctanos
            </Link>
            {/* <Link to='/' id='report'>
              Reportar bug / Solicitar feature
            </Link> */}
          </div>
      </div>
    </div>
  )
}