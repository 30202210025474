import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { CPArticle } from "../Homepage/types";
import { GET_NEWS } from "./connection";

import { NewsType } from "./types.d";

import "./index.scss";
import { calculateURL, humanDate } from "../../Utils";

export default function News() {
  /** @todo Refactor con CSS o Componente */
  const [mainArticle, setMainArticle] = useState<CPArticle>();
  const [articles, setArticles] = useState<CPArticle[]>([]);
  const [minorArticles, setMinorArticles] = useState<CPArticle[]>([]);
  const [minorArticlesSecond, setMinorArticlesSecond] = useState<CPArticle[]>([]);

  const { data, loading } = useQuery<NewsType.Response>(GET_NEWS);

  useEffect(() => {
    if (data?.CP_Article) {

      const filterArticles = data.CP_Article.filter(article => article.category.id === "1");
      const [_mainArticle, ..._articles] = filterArticles; 

      setMainArticle(_mainArticle);
      setArticles(_articles.slice(0, 2));
      setMinorArticles(_articles.slice(2, 5));
      setMinorArticlesSecond(_articles.slice(5, 8));
    }
  }, [data]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="News animate__animated animate__fadeIn animate__fast">
      {/* <SearchSection /> */}

      {/* Artículo principal */}
      {mainArticle && (
        <article className="News__Highlight">
          <div className="News__Highlight_Wrapper">
            <div className="News__Highlight_Content">
              <div className="News__Highlight_Content_In">
                <div
                  className="News__Highlight_Image"
                  style={{
                    backgroundImage: mainArticle.image
                      ? `url("https://api-web.crece.pro/assets/${mainArticle.image.id}.png")`
                      : undefined,
                  }}
                ></div>
              </div>
            </div>
            <div className="News__Highlight_Content">
              <div className="News__Highlight_Content_In">
                <div className="News__Highlight_Text">
                  <h2>
                    <Link to={calculateURL(mainArticle)}>
                      {mainArticle?.title}
                    </Link>
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: mainArticle.resume,
                    }}
                  />
                  <div className="News__Highlight_Text_Small">{humanDate(mainArticle)}</div>
                </div>
              </div>
            </div>
          </div>
        </article>
      )}

      <section className="News__Articles">
        <div className="News__Articles_Wrapper">
          {articles.map((article) => (
            <div className="News__Articles_Unit">
              <div className="News__Articles_Content">
                <div className="News__Articles_Content_In">
                  <div
                    className="News__Articles_Image"
                    style={{
                      backgroundImage: article.image
                        ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")`
                        : undefined,
                    }}
                  ></div>
                  <div className="News__Articles_Text">
                    <h3 className="News__Articles_Text_Title">
                      <Link to={calculateURL(article)}>{article?.title}</Link>
                    </h3>
                    <div className="News__Articles_Text_Small">{humanDate(article)}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="News__MinorArticles">
        <div className="News__MinorArticles_Wrapper">
          {minorArticles.map((article) => (
            <div className="News__MinorArticles_Unit">
              <div className="News__MinorArticles_Content">
                <div className="News__MinorArticles_Content_In">
                  <div
                    className="News__MinorArticles_Image"
                    style={{
                      backgroundImage: article.image
                        ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")`
                        : undefined,
                    }}
                  ></div>
                  <div className="News__MinorArticles_Text">
                    <h3 className="News__MinorArticles_Text_Title">
                      <Link to={calculateURL(article)}>{article?.title}</Link>
                    </h3>
                    <div className="News__MinorArticles_Text_Small">{humanDate(article)}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="News__MinorArticles">
        <div className="News__MinorArticles_Wrapper">
          {minorArticlesSecond.map((article) => (
            <div className="News__MinorArticles_Unit">
              <div className="News__MinorArticles_Content">
                <div className="News__MinorArticles_Content_In">
                  <div
                    className="News__MinorArticles_Image"
                    style={{
                      backgroundImage: article.image
                        ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")`
                        : undefined,
                    }}
                  ></div>
                  <div className="News__MinorArticles_Text">
                    <h3 className="News__MinorArticles_Text_Title">
                      <Link to={calculateURL(article)}>{article?.title}</Link>
                    </h3>
                    <div className="News__MinorArticles_Text_Small">{humanDate(article)}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
