import { useState } from "react";
import { Link } from "react-router-dom";
import {
  MdInfoOutline,
  MdOutlineFeed,
  MdOutlineHome,
  MdOutlineMenu,
  MdWorkspacesOutline,
} from "react-icons/md";

import "./index.scss";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../Assets/Icons";
import YoutubeIcon from "../../Assets/Icons/YoutubeIcon";

interface AppFrameProps {
  children: React.ReactNode;
}

export default function AppFrame({ children }: AppFrameProps) {
  /**
   * - Por defecto, parte en `false`.
   * - En la variable `darkMode` almacenaré el estado actual del modo oscuro.
   * - En la función `setDarkMode` podré cambiar el estado del modo oscuro.
   */
  // const [darkMode, setDarkMode] = useState<boolean>(false)

  const [menu, setMenu] = useState(false);

  const toggleMenu = () => {
    setMenu(!menu);
  };

  return (
    <div className="AppFrame__Wrapper">
      {/* Header */}
      <header className="AppFrame__Menu">
        <div className="AppFrame__Menu_Wrapper">
          {/* Logotipo de Centro.pro */}
          <Link to="/" className="AppFrame__Menu_Wrapper_Logo">
            Blog Crece.<b>pro</b>
          </Link>

          <button onClick={toggleMenu}>
            <MdOutlineMenu />
          </button>

          <div className={`AppFrame__Menu_Nav ${menu ? "isActive" : ""}`}>
            <div className="AppFrame__Menu_Nav_Links">
              <Link
                to="/"
                id="home"
                onClick={toggleMenu}
                className="AppFrame__Menu_Nav_Links_Unit"
              >
                <MdOutlineHome /> Home
              </Link>

              <Link
                to="/noticias"
                onClick={toggleMenu}
                className="AppFrame__Menu_Nav_Links_Unit"
              >
                <MdOutlineFeed />
                Noticias
              </Link>

              {/* <Link to='/' onClick={ toggleMenu } className='AppFrame__Menu_Nav_Links_Unit'>
                  <MdOutlineAssignmentLate/>Novedades
                </Link>

                <Link to='/' onClick={ toggleMenu } className='AppFrame__Menu_Nav_Links_Unit'>
                  <MdOutlineAdjust/>Componentes
                </Link> */}

              <Link
                to="/ayuda"
                onClick={toggleMenu}
                className="AppFrame__Menu_Nav_Links_Unit"
              >
                <MdInfoOutline />
                Ayuda
              </Link>

              <Link
                target="_blank"
                to={{
                  pathname: "https://app.crece.pro",
                }}
                onClick={toggleMenu}
                className="AppFrame__Menu_Nav_Links_GoToApp"
              >
                <MdWorkspacesOutline />
                Ir a Crece.pro
              </Link>

              {/* <Link to='/' onClick={ toggleMenu } className='AppFrame__Menu_Nav_Links_DarkMode'>
                  <MdOutlineDarkMode /><div id='dark'>Modo Oscuro</div>
                </Link> */}
            </div>

            <div className="AppFrame__Menu_Nav_SocialIcons">
              <div className="AppFrame__Menu_Nav_SocialIcons_Unit">
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://www.facebook.com/app.crece.pro",
                  }}
                >
                  <FacebookIcon />
                </Link>
              </div>
              <div className="AppFrame__Menu_Nav_SocialIcons_Unit">
                <Link
                  target="_blank"
                  to={{
                    pathname:
                      "https://instagram.com/crece.pro?igshid=NzAzN2Q1NTE=",
                  }}
                >
                  <InstagramIcon />
                </Link>
              </div>
              <div className="AppFrame__Menu_Nav_SocialIcons_Unit">
                <Link
                  target="_blank"
                  to={{
                    pathname:
                      "https://twitter.com/crecepro?s=21&t=UZcQr1UxgOtYpBuzN-LhFw",
                  }}
                >
                  <TwitterIcon />
                </Link>
              </div>
              <div className="AppFrame__Menu_Nav_SocialIcons_Unit">
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://www.linkedin.com/company/app-crece-pro/",
                  }}
                >
                  <LinkedinIcon />
                </Link>
              </div>
              <div className="AppFrame__Menu_Nav_SocialIcons_Unit">
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://youtube.com/@crecepro637",
                  }}
                >
                  <YoutubeIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Content */}
      <div className="AppFrame__Content">{children}</div>

      {/* Footer */}
      <footer className="AppFrame__Footer">
        <div id="footer_wrapper">
          <div className="footer_columns">
            <div className="footer_unit">
              <div className="footer_unitHeader">Crece.pro</div>
              {/* <div className='social_icons'> */}
              <div className="footer_unitLink">
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://www.facebook.com/app.crece.pro",
                  }}
                >
                  <FacebookIcon />
                  <p>Facebook</p>
                </Link>
              </div>
              <div className="footer_unitLink">
                <Link
                  target="_blank"
                  to={{
                    pathname:
                      "https://instagram.com/crece.pro?igshid=NzAzN2Q1NTE=",
                  }}
                >
                  <InstagramIcon />
                  <p>Instagram</p>
                </Link>
              </div>
              <div className="footer_unitLink">
                <Link
                  target="_blank"
                  to={{
                    pathname:
                      "https://twitter.com/crecepro?s=21&t=UZcQr1UxgOtYpBuzN-LhFw",
                  }}
                >
                  <TwitterIcon />
                  <p>Twitter</p>
                </Link>
              </div>
              <div className="footer_unitLink">
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://www.linkedin.com/company/app-crece-pro/",
                  }}
                >
                  <LinkedinIcon />
                  <p>LinkedIn</p>
                </Link>
              </div>
              <div className="footer_unitLink">
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://youtube.com/@crecepro637",
                  }}
                >
                  <YoutubeIcon />
                  <p>Youtube</p>
                </Link>
              </div>
              {/* </div> */}
            </div>
            {/* <div className='footer_unit'>
              <div className='footer_unitHeader'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
            </div>
            <div className='footer_unit'>
              <div className='footer_unitHeader'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
            </div>
            <div className='footer_unit'>
              <div className='footer_unitHeader'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
            </div>
            <div className='footer_unit'>
              <div className='footer_unitHeader'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
              <div className='footer_unitLink'>Lorem Ipsum</div>
            </div> */}
          </div>
          <div id="footer_legalSocial">
            <small id="footer_copyright">
              © 2021 Crece.pro - Todos los derechos reservados
            </small>
            {/* <div id='footer_legalGrid'>
              <small>Seguridad</small>
              <small>Legal</small>
              <small>Privacidad</small>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
}
