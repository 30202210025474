const LOGIN_USER = 'LOGIN_USER'
const EXTERNAL_LOGIN = 'EXTERNAL_LOGIN'
const LOGOUT = 'LOGOUT'
const SET_LEARNING_ROUTES = 'SET_LEARNING_ROUTES'

const initialState: any = {
    token: null,
    isLogged: false,
    userInfo: {},
    learningRoutes: []
}

const updateState = (state: any, props: any) => Object.assign({}, state, {
    ...props
})


/**
 * Siempre retorna el state completo.
 */
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case LOGIN_USER:
            return updateState(state, {
                ...action.payload    
            });
        case LOGOUT:
            return updateState(state, {
                token: null,
                userInfo: null,
                isLogged: false
            })
        case SET_LEARNING_ROUTES:
            return updateState(state, {
                ...action.payload
            })
        case EXTERNAL_LOGIN:
            return updateState(state, {
                ...action.payload
            })
        /* case BLOCK_NAVIGATION:
            return updateState(state, {
                blockNavigation: action.payload
            })*/

    }
    return state
}

export default rootReducer