import { gql } from '@apollo/client'

export const GET_ARTICLE_SINGLE = gql`
 query getArticlesFromHome ($ID: ID!) {
		CP_Article_by_id(id: $ID) {
			category {
				id
				icon
				title
			}
			content
			date_created
			date_updated
			id
			resume
			sort
			status
			title
			image {
				id
			}
			help_section {
				id
				icon
				title
			}
			comments {
				id
				title
				comment
			}
		}
		CP_Article (sort: ["sort", "date_created"]){
			category {
				id
				icon
				title
			}
			content
			date_created
			date_updated
			id
			resume
			sort
			status
			title
			image {
				id
			}
			help_section {
				id
				icon
				title
			}
		}
		CP_Help_Sections {
			id
			icon
			title
		}
	}
`
