// Librerías externas
import { BrowserRouter as Router, Switch } from 'react-router-dom'

// Librerías internas
import useMobile from '../Hooks/useMobile'

// Librerías locales de la misma carpeta (`Routes`)
import { PublicRoute } from './'

// Vistas de la aplicación
import { Help, HelpByCategory, Homepage, News, NewsArticle, SearchByCategory } from '../Views'

export default function ContingenteRouter () {
  const { ViewportClass } = useMobile()

  return (
    <Router>
      <div className={`App ${ViewportClass}`}>
        <Switch>
          {/* Public Routes */}
          <PublicRoute restricted component={Help} path='/ayuda' exact />
          <PublicRoute restricted component={HelpByCategory} path='/ayuda/:sectionId/:slug' exact />
          <PublicRoute restricted component={Homepage} path='/' exact />
          <PublicRoute restricted component={News} path='/noticias' exact />
          <PublicRoute restricted component={NewsArticle} path='/:categoryTitle/:helpSection/:articleId/:slug'  />
          <PublicRoute restricted component={SearchByCategory} path='/searchbycategory' exact />
          {/* Private Routes */}
          {/* <PrivateRoute restricted component={Home} path='/home' exact /> */}          
        </Switch>
      </div>
    </Router>
  )
}
