import { Link, useParams } from "react-router-dom";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../Assets/Icons";
import { LowerArticles } from "../../Components";
import { NewsArticleType } from "./types";

import { useQuery } from "@apollo/client";
import { GET_ARTICLE_SINGLE } from "./connection";
import { useEffect, useState } from "react";
import { CPArticle } from "../Homepage/types";
import { calculateURL, helpCategoryURL, humanDate } from "../../Utils";

import "./index.scss";
import { MdKeyboardBackspace } from "react-icons/md";

const parseParams = (params: NewsArticleType.RouteParams) => {
  return {
    articleId: Number(params.articleId),
    slug: params.slug,
  };
};

export default function NewsArticle() {
  const { articleId } = parseParams(useParams<NewsArticleType.RouteParams>());

  const [article, setArticle] = useState<CPArticle>();
  const [allArticles, setAllArticles] = useState<CPArticle[]>([]);

  /** @todo loading, error */
  const { data, loading } = useQuery<NewsArticleType.Response>(
    GET_ARTICLE_SINGLE,
    {
      variables: {
        ID: articleId,
      },
    }
  );

  useEffect(() => {
    if (data?.CP_Article_by_id) {
      setArticle(data.CP_Article_by_id);
    }
    if (data?.CP_Article) {
      setAllArticles(data.CP_Article);
    }
  }, [data]);

  function sortRecommendedArticles(articles: CPArticle[]): CPArticle[] {
    articles = articles.map((article: CPArticle) => {
      return {
        ...article,
        date_created: new Date(article.date_created),
      };
    });
    articles.sort((a: CPArticle, b: CPArticle) => {
      const fechaA = (a.date_created as Date).getTime();
      const fechaB = (b.date_created as Date).getTime();
      return fechaB - fechaA;
    });
    return articles.slice(0, 3);
  }

  const recommendedArticles = sortRecommendedArticles(allArticles);

  const filterArticles = allArticles.filter(
    (articleFilter) =>
      articleFilter.help_section?.id === article?.help_section?.id
  );

  const filteredHelpArticles = filterArticles.filter((article: CPArticle , i: number): boolean => i !== 0);

  function otherArticles(article: CPArticle): CPArticle[] {
    const articlePosition = filterArticles?.findIndex(
      (articlePosition: CPArticle) => articlePosition.id === article?.id
    );
    return [
      filterArticles[articlePosition - 1],
      filterArticles[articlePosition + 1],
    ];
  }

  article?.help_section && otherArticles(article);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="Article animate__animated animate__fadeIn animate__faster">
      {article?.category.id === "3" && (
        <div className="Article__Category">
          <Link to="/ayuda">
            <MdKeyboardBackspace />
            <h3>Ayuda&nbsp;</h3>
          </Link>
          <Link to={helpCategoryURL(article?.help_section)}>
            <h3>/ {article?.help_section?.title}&nbsp; </h3>
          </Link>
          <h3>/ {article?.title}</h3>
        </div>
      )}
      <article className="Article__All">
        <div className="Article__Highlight">
          <div className="Article__Highlight_Wrapper">
            <div className="Article__Highlight_Content">
              <div className="Article__Highlight_Content_In">
                <div
                  className="Article__Highlight_Image"
                  style={{
                    backgroundImage: article?.image
                      ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")`
                      : undefined,
                  }}
                ></div>
              </div>
            </div>
            <div className="Article__Highlight_Content">
              <div className="Article__Highlight_Content_In">
                <div className="Article__Highlight_Text">
                  <div className="Article__Blog_Category">
                    {/* @todo Conversar con Fran las múltiples categorías/tags para un articulo */}
                    {article?.category.id === "1" ? (
                      <Link to="/noticias" id="link_category">
                        {article.category.title}
                      </Link>
                    ) : (
                      <Link to="/ayuda" id="link_category">
                        {article?.category.title}
                      </Link>
                    )}
                  </div>
                  <h1>{article?.title}</h1>
                  {article?.date_created && (
                    <div className="text_small">{humanDate(article)}</div>
                  )}
                  <div className="Article__Highlight_Text_SocialIcons">
                    <div className="Article__Highlight_Text_SocialIcons_UnitLink">
                      <FacebookIcon />
                    </div>
                    <div className="Article__Highlight_Text_SocialIcons_UnitLink">
                      <InstagramIcon />
                    </div>
                    <div className="Article__Highlight_Text_SocialIcons_UnitLink">
                      <TwitterIcon />
                    </div>
                    <div className="Article__Highlight_Text_SocialIcons_UnitLink">
                      <LinkedinIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="Article__Body"
          dangerouslySetInnerHTML={{
            __html: article?.content!,
          }}
        ></div>

        {article?.category.id === "3" && (
          <div className="Article__Buttons">
            {otherArticles(article)[0] !== filterArticles[0] && (
              <Link
                to={calculateURL(otherArticles(article)[0])}
                className="Article__Buttons_Link"
              >
                <div className="Article__Buttons_Previous">
                  Anterior: {otherArticles(article)[0].title}
                </div>
              </Link>
            )}

            {otherArticles(article)[1] && (
              <Link
                to={calculateURL(otherArticles(article)[1])}
                className="Article__Buttons_Link"
              >
                <div className="Article__Buttons_After">
                  Siguiente: {otherArticles(article)[1].title}
                </div>
              </Link>
            )}
          </div>
        )}
      </article>

      {article?.category.id === "3" ? (
        <div className="Article__LowerArticles">
          <h5>Más sobre {article?.help_section?.title}</h5>
          <LowerArticles articles={filteredHelpArticles} />
        </div>
      ) : (
        <div className="Article__LowerArticles">
          <h5>Te podria interesar...</h5>
          <LowerArticles articles={recommendedArticles} />
        </div>
      )}
    </div>
  );
}
