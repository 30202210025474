import { Link } from 'react-router-dom'
import useMessage from '@rottitime/react-hook-message-event'

import './index.scss'
// import useMobile from '../../Hooks/useMobile'

import { useDispatch } from 'react-redux'
import { externalLogin } from '../../Store/Actions'
import { SearchSection } from '../../Components';
import { MdMailOutline, MdOutlineHelpOutline, MdSupport } from 'react-icons/md'

export default function SearchByCategory() {
  // const { isMobile } = useMobile()
  const dispatch = useDispatch()

  useMessage('external-login', (send: any, payload: any) => {
    console.log({ payload })
    dispatch(externalLogin(payload))
  })

  return (
    <div className='Search'>
      
      <SearchSection />

      <section className='Search__Articles'>
        <h2><span id='help'>Lorem</span>, 2 resultados</h2>
        <div className='Search__Articles_Wrapper'>
          <div className='Search__Articles_Unit'>
            <div className='Search__Articles_Content'>
              <div className='Search__Articles_Content_In'>
                <div className='Search__Articles_Image'></div>
                <div className='Search__Articles_Text'>
                  <div className='Search__Articles_Category'>
                    <Link to='/' id='link_search'>
                      Lorem
                    </Link>
                    <Link to='/' id='link_search'>
                      Ipsum
                    </Link>
                    <Link to='/' id='link_search'>
                      dolor
                    </Link>
                  </div>
                  <h3>Lorem ipsum, dolor sit amet consectetur.</h3>
                  <div className='text_small'>Por Nombre Apellido, 30 de Agosto, 2022</div>
                </div>
              </div>
            </div>
          </div>
          <div className='Search__Articles_Unit'>
            <div className='Search__Articles_Content'>
              <div className='Search__Articles_Content_In'>
                <div className='Search__Articles_Image'></div>
                <div className='Search__Articles_Text'>
                  <div className='Search__Articles_Category'>
                    <Link to='/' id='link_search'>
                      Lorem
                    </Link>
                    <Link to='/' id='link_search'>
                      Ipsum
                    </Link>
                    <Link to='/' id='link_search'>
                      dolor
                    </Link>
                  </div>
                  <h3>Lorem ipsum, dolor sit amet consectetur.</h3>
                  <div className='text_small'>Por Nombre Apellido, 30 de Agosto, 2022</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='Search__Suggestions'>
        <div className="Search__Suggestions_Table">
          <MdOutlineHelpOutline/>
          <h3>¿No encontraste lo que <span id='search'>buscabas</span>?</h3>
        </div>
        <div className="Search__Suggestions_Table">
          <MdSupport/>
          <h3>Revisa en nuestra sección de <span id='help'>ayuda</span></h3>
        </div>
        <div className="Search__Suggestions_Table">
          <MdMailOutline/>
          <h3>O salta todos los tutoriales y <span id='contact'>contactanos</span></h3>
        </div>
      </div>

    </div>
  )
}
