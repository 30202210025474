import { Link } from 'react-router-dom'
import { calculateURL } from '../../Utils'
import { ArticleBoxProps } from './types'

import './index.scss'

export default function ArticleBox({
  article,
  hasResume = false
}: ArticleBoxProps) {
  return (
    <article className='ArticleUnit'>
      <div id='article_thumbnail' style={{
        backgroundImage: article.image ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")` : undefined
      }}>
        {(article.category.id === "1") 
          ? <Link to='/noticias' id='blog_articles'>
              {article.category.title}
            </Link>
          : <Link to='/ayuda' id='blog_articles'>
              {article.category.title}
            </Link>
        }
      </div>
      <h3 className='article_title'>
        <Link to={calculateURL(article)}>{article.title}</Link>
      </h3>
      {/* <button id='blog_search_button'>
          <MdMoreVert />
        </button> */}
      {hasResume && (
        <p
          className='article_body'
          dangerouslySetInnerHTML={{
            __html: article.resume
          }}
        />
      )}
    </article>
    // <div>
    //   <div id='highlight_image_news'>
    //     <Link to='/' id='blog_articles'>
    //       {article.category.title}
    //     </Link>
    //   </div>
    //   <div className='news_button'>
    //     <h4>{article.title}</h4>

    //   </div>
    // </div>
  )
}
