import { Link } from 'react-router-dom'
import useMessage from '@rottitime/react-hook-message-event'
import { MdOutlineSchool, MdOutlineAssignment, MdOutlineWorkOutline, MdOutlineMonetizationOn } from "react-icons/md";
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client';

import { externalLogin } from '../../Store/Actions'
// import { SearchSection } from '../../Components';

import { GET_ARTICLES_HOME } from './connection';
import './index.scss'
import { useEffect, useState } from 'react';
import { CPArticle, CPHelpSection, GetArticlesFromHome } from './types';
import ArticleHightlight from '../../Components/ArticleHightlight';
import ArticleBox from '../../Components/ArticleBox';
import { helpCategoryURL } from '../../Utils';

export default function Homepage() {
  const dispatch = useDispatch()


  /**
   * - Por defecto, parte con un arreglo vacío.
   * - En la variable `articles` almacenaré los artículos a mostrar en el Home.
   * - En la función `setArticles` podré cambiar los artículos que se están renderizando en el Home.
   */
  const [articles, setArticles] = useState<CPArticle[]>([])
  const [highlight, setHighlight] = useState<CPArticle>()
  const [helpSections, setHelpSections] = useState<CPHelpSection[]>([])

  /**
   * - En la variable `data` estará disponible el resultado de la API tipificado como `GetArticlesFromHome`.
   * - En la variable `loading` estará disponible el estado de la petición.
   * - En la variable `error` estará disponible el error de la petición, de tipo `ApolloError`.
   */
  const { data } = useQuery<GetArticlesFromHome>(GET_ARTICLES_HOME)

  /**
   * Este hook de efecto, actualizará el estado de `articles` cada vez que se actualice el estado de `data`.
   */
  useEffect(() => {
    if (data?.CP_Article) {
      const [_highlight, ..._articles] = data.CP_Article

      setHighlight(_highlight)
      setArticles(_articles.slice(0, 4))
    }

    if (data?.CP_Help_Sections) {
      setHelpSections(data.CP_Help_Sections)
    }
  }, [data])

  useMessage('external-login', (send: any, payload: any) => {
    console.log({ payload })
    dispatch(externalLogin(payload))
  })

  return (
    <div className='Homepage animate__animated animate__fadeIn animate__fast'>
      
      {/* <SearchSection /> */}

      <section className='Homepage__Highlight'>
        <div id='highlight_wrapper_new'>
          <div id='highlight_text_new'>
            {highlight && <ArticleHightlight article={highlight} />}
          </div>
          <div id='highlight_text_new'>
            <div id='highlight_textContent_new'>
                <h5>Novedades</h5>
                  <div className='container'>
                    {articles.map((article, index) => (
                      <ArticleBox key={`ArticleBox${index}`} article={article} />
                    ))}
                    {/* <div className='odds'>
                      <div id='highlight_image_news'>
                        <Link to='/' id='blog_articles'>
                          Lorem
                        </Link>
                      </div>
                      <div className='news_button'>
                        <h4>
                          Lorem ipsum
                        </h4>
                        <button id='blog_search_button'>
                          < MdMoreVert />
                        </button>
                      </div>
                    </div>
                    <div>
                      <div id='highlight_image_news'>
                        <Link to='/' id='blog_articles'>
                          Lorem
                        </Link>
                      </div>
                        <div className='news_button'>
                          <h4>
                            Lorem ipsum
                          </h4>
                          <button id='blog_search_button'>
                            < MdMoreVert />
                          </button>
                        </div>
                    </div>
                    <div className='odds'>
                      <div id='highlight_image_news'>
                        <Link to='/' id='blog_articles'>
                          Lorem
                        </Link>
                      </div>
                        <div className='news_button'>
                          <h4>
                            Lorem ipsum
                          </h4>
                          <button id='blog_search_button'>
                            < MdMoreVert />
                          </button>
                        </div>
                    </div>
                    <div>
                      <div id='highlight_image_news'>
                        <Link to='/' id='blog_articles'>
                          Lorem
                        </Link>
                      </div>
                        <div className='news_button'>
                          <h4>
                            Lorem ipsum
                          </h4>
                          <button id='blog_search_button'>
                            < MdMoreVert />
                          </button>
                        </div>
                    </div> */}
                  </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Homepage__Sections">
        <h5>Secciones</h5>
        <div className="Homepage__Sections_Cells">
          { helpSections.map((section, index) => (
            (section.id === "1" ? 
              <Link to={helpCategoryURL(section)}>
                <div className='Homepage__Sections_Buttons' id='first_steps'>
                  <MdOutlineSchool/>
                  <button className='Homepage__Sections_Buttons_Each'>{section.title}</button>
                </div>
              </Link>
            : section.id === "3" ? 
              <Link to={helpCategoryURL(section)}>
                <div className='Homepage__Sections_Buttons' id='tasks'>
                  <MdOutlineAssignment/>
                  <button className='Homepage__Sections_Buttons_Each'>{section.title}</button>
                </div>
              </Link>
            : section.id === "4" ?
              <Link to={helpCategoryURL(section)}>
                <div className='Homepage__Sections_Buttons' id='clients'>
                  <MdOutlineWorkOutline/>
                  <button className='Homepage__Sections_Buttons_Each'>{section.title}</button>
                </div>
              </Link>
            : section.id === "5" ?
              <Link to={helpCategoryURL(section)}>
                <div className='Homepage__Sections_Buttons' id='sales'>
                  <MdOutlineMonetizationOn/>
                  <button className='Homepage__Sections_Buttons_Each'>{section.title}</button>
                </div>
              </Link>
            : "")
          ))}
        </div>
      </section>

      <section className='Homepage__Articles'>
        <h5>Articulos Populares</h5>
        <div className='Homepage__Articles_Wrapper'>
          {articles.slice(0,3).map((article, index) => (
            <ArticleBox key={`ArticleBox${index}`} article={article} hasResume />
          ))}
        </div>
      </section>

      {/* <section className="Homepage__Sections">
        <h5>Ayuda con Crece.pro</h5>
        <div className="Homepage__Sections_Cells">
          <div className='Homepage__Sections_Buttons' id='faq'>
            <Link to=''><MdOutlineHelpOutline/></Link>
            <button className='Homepage__Sections_Buttons_Each'>FAQ</button>
          </div>
          <div className='Homepage__Sections_Buttons' id='feedback'>
            <Link to=''><MdOutlineMicNone/></Link>
            <button className='Homepage__Sections_Buttons_Each'>Feedback</button>
          </div>
          <div className='Homepage__Sections_Buttons' id='bugs'>
            <Link to=''><MdOutlineBugReport/></Link>
            <button className='Homepage__Sections_Buttons_Each'>Bugs</button>
          </div>
          <div className='Homepage__Sections_Buttons' id='attendance'>
            <Link to=''><MdOutlineEmail/></Link>
            <button className='Homepage__Sections_Buttons_Each'>Asistencia</button>
          </div>
        </div>
      </section> */}

      

      {/* <!--MODAL CATEGORY--> */}
      {/* <dialog id='modalCategory' className='modalWrapper'>
        <div id='modalCategory__close'>
          <div className='modalContent'>
            <div id='modalContent_Category'>
              <small id='modalContent_CategoryText'>
                Filtra segun categoria
              </small>
              <ul id='modalContent_CategoryList'>
                <li>Todo</li>
                <li>Noticias</li>
                <li>Tips</li>
                <li>Categorias</li>
                <li>Tutoriales</li>
                <li>Reseñas</li>
              </ul>
            </div>
          </div>
        </div>
      </dialog> */}

      {/* <!--MODAL SEARCH--> */}
      {/* <dialog id='modalSearch' className='modalWrapper'>
        <div id='modalSearch__close'>
          <div className='modalContent'>
            <div id='modalContent_search'>
              <small id='modalContent_searchText'>Buscador</small>
              <div id='modalContent_searchBar'>
                <input type='search' id='modalContent_searchInput' />
                <button id='modalContent_searchBtn'>
                  <svg viewBox='0 0 24 24'>
                    {' '} */}
                    {/** style="width:24px;height:24px"  */}
                    {/* <path
                      fill='currentColor'
                      d='M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </dialog> */}
    </div>
  )
}
