import useMessage from "@rottitime/react-hook-message-event";
import { useDispatch } from "react-redux";
import { externalLogin } from "../../Store/Actions";
import {
  MdOutlineTour,
  MdOutlineGroupWork,
  MdOutlineAssignment,
  MdWorkOutline,
  MdOutlineMonetizationOn,
  MdSearch,
} from "react-icons/md";
import { useQuery } from "@apollo/client";

import "./index.scss";
import { GET_ARTICLES_HELP } from "./connection";
import {
  CPArticle,
  CPHelpSection,
  GetArticlesFromHome,
} from "../Homepage/types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { calculateURL } from "../../Utils";

export default function Help() {
  // const { isMobile } = useMobile()
  const dispatch = useDispatch();

  const [articles, setArticles] = useState<CPArticle[]>([]);
  const [sections, setSections] = useState<CPHelpSection[]>([]);

  const [search, setSearch] = useState("");

  const { data } = useQuery<GetArticlesFromHome>(GET_ARTICLES_HELP);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  useEffect(() => {
    if (data?.CP_Article) {

      const filterArticles = data.CP_Article.filter(article => article.category.id === "3");
      const [..._articles] = filterArticles;

      setArticles(_articles);
    }

    if (data?.CP_Help_Sections) {
      setSections(data.CP_Help_Sections);
    }
  }, [data]);

  useMessage("external-login", (send: any, payload: any) => {
    console.log({ payload });
    dispatch(externalLogin(payload));
  });

  const results = !search
    ? null
    : articles.filter(
        (dato) =>
          dato.title.toLowerCase().includes(search.toLocaleLowerCase()) ||
          dato.resume.toLowerCase().includes(search.toLocaleLowerCase()) ||
          dato.content.toLowerCase().includes(search.toLocaleLowerCase())
      );

  return (
    <div className="Help animate__animated animate__fadeIn animate__fast">
      <div className="Help__Search">
        <h1>
          ¿Como te podemos <span id="help_you">ayudar?</span>
        </h1>
        <div className="Help__Search_Bar">
          <input
            type="text"
            value={search}
            onChange={handleChange}
            placeholder="Busca lo que necesites"
          />
          <button>
            <MdSearch />
          </button>
        </div>
      </div>

      <section className="Help__Articles">
        <div className="Help__Articles_Wrapper">
          {results?.map((article) => (
            <div className="Help__Articles_Unit">
              <Link to={calculateURL(article)}>
                <div className="Help__Articles_Content">
                  <div className="Help__Articles_Content_In">
                    <div
                      className="Help__Articles_Image"
                      style={{
                        backgroundImage: article.image
                          ? `url("https://api-web.crece.pro/assets/${article.image.id}.png")`
                          : undefined,
                      }}
                    ></div>
                    <div className="Help__Articles_Text">
                      <h3 className="Help__Articles_Text_Title">
                        {article?.title}
                      </h3>
                      <div
                        className="Help__Articles_Text_Small"
                        dangerouslySetInnerHTML={{
                          __html: article.resume,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section>

      {/* <div className='Help__Sections'>
          <h2>Secciones de ayuda</h2>
          <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel inventore perferendis ex!</h3>
          <div className='Help__Sections_Frame'>

            <div className='Help__Sections_Frame_Unit'>
              <MdOutlineSchool/>
              <h3>Tutoriales</h3>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. In nostrum fugiat minima consequuntur numquam molestiae nesciunt libero velit id minus possimus nisi quia quod ipsa error placeat, tempore facere maxime!</p>
            </div>
            <div className='Help__Sections_Frame_Unit'>
              <MdOutlineNotListedLocation/>
              <h3>FAQ</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt reprehenderit unde quam maiores itaque aut non, rerum ipsa repellat esse, accusamus quis labore adipisci nihil sequi similique qui molestias ea!</p>
            </div>
            <div className='Help__Sections_Frame_Unit'>
              <MdOutlineCorporateFare/>
              <h3>Tu Empresa</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate, quas? Quaerat eveniet, cupiditate nulla, tempore minus a sint suscipit voluptas alias voluptate libero nostrum harum ratione quo, pariatur nisi dolores?</p>
            </div>
          </div>
        </div> */}

      <div className="Help__Crecepro">
        <h2>Artículos de ayuda por secciones</h2>
        {/* <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel inventore perferendis ex!</h3> */}
        <div className="Help__Crecepro_Frame">
          {sections.map((section, i) => {
            let articleId = "";
            articleId = (i + 1).toString();

            let articlesByCategory = articles
              .filter((article) => article.help_section?.id === articleId)
              .sort((a, b) => Number(a.id) - Number(b.id));
            let [, ...othersArticlesByCategory] = articlesByCategory;

            let articleTitleFlavor = articlesByCategory[0].content.split(
              /\r\n|\r|\n/,
              -1
            );

            return (
              <details className="Expandable">
                <summary>
                  {articleId === "1" ? (
                    <div id="tour">
                      <MdOutlineTour />
                    </div>
                  ) : articleId === "2" ? (
                    <div id="work">
                      <MdOutlineGroupWork />
                    </div>
                  ) : articleId === "3" ? (
                    <div id="assignment">
                      <MdOutlineAssignment />
                    </div>
                  ) : articleId === "4" ? (
                    <div id="client">
                      <MdWorkOutline />
                    </div>
                  ) : articleId === "5" ? (
                    <div id="sale">
                      <MdOutlineMonetizationOn />
                    </div>
                  ) : (
                    ""
                  )}

                  <h4>{section.title}</h4>

                  <div
                    className="TitleFlavor"
                    dangerouslySetInnerHTML={{
                      __html: articleTitleFlavor[0],
                    }}
                  ></div>
                </summary>
                <ul>
                  {othersArticlesByCategory.map((article, i) => {
                    let articleFlavor = article.content.split(/\r\n|\r|\n/, -1);
                    return (
                      <li>
                        <Link to={calculateURL(article)}>
                          <h5>{article.title}</h5>
                          <span
                            className="ArticleFlavor"
                            dangerouslySetInnerHTML={{
                              __html: articleFlavor[0],
                            }}
                          ></span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </details>
            );
          })}
        </div>
      </div>

      {/* <div className="Help__Contact">
        <ContactUs />
      </div> */}
    </div>
  );
}
