import { CPArticle } from "../Views/Homepage/types"

export const calculateURL = (article: CPArticle) => {

  let indexTitle = article.title.indexOf(",");
  const slug = encodeURIComponent(article.title.replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, ""));

  const params = [
    encodeURIComponent(article.category.title.replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "")),
    article.help_section? encodeURIComponent(article.help_section.title.replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "")) : "blog",
    article.id,
    article.title.includes(',')? slug.substring(0, indexTitle) : slug,
  ]
  return `/${params.join('/').toLowerCase()}`
}
